<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-for="page in pages"
      :key="page.slug"
      class="accordion-table__body"
    >
      <div
        v-b-toggle="`accordion-${page.uuid}`"
      >
        <page-table-item
          ref="page"
          :level="level"
          :page="page"
          @switch="onSwitch"
          @switch-index="onSwitchIndex"
        />
      </div>
      <b-collapse
        :id="`accordion-${page.uuid}`"
        :accordion="`pages-accordion-${level}`"
        @show="currentPage = page.uuid"
      >
        <div
          v-if="page.children_count > 0"
          class="accordion-table__children"
        >
          <div
            v-if="currentPage === page.uuid"
            class="accordion-table"
          >
            <page-table-list
              ref="pages"
              :parent-u-u-i-d="page.uuid"
              :level="level + 1"
            />
          </div>
        </div>
      </b-collapse>
    </div>
  </b-overlay>
</template>

<script>
import { VBToggle, BOverlay, BCollapse } from 'bootstrap-vue'
import Page from '@/api/http/models/page/Page'
import { mapGetters } from 'vuex'
import PageTableItem from './PageTableItem.vue'

export default {
  name: 'PageTableList',
  components: {
    PageTableItem,
    BOverlay,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    parentUUID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pages: [],
      currentPage: '',
      showLoading: true,
      showChildren: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Page.getAll(this.currentSite.slug, this.parentUUID)

    if (response.data) {
      this.pages = response.data
    }

    this.showLoading = false
  },
  methods: {
    changeLock(value) {
      if (this.$refs.page.length) {
        this.$refs.page.forEach(page => {
          page.changeLock(value)
        })
      }
    },
    onSwitch(value) {
      if (this.$refs.pages) {
        this.$refs.pages[0].changeLock(value)
      }
    },
    changeLockIndex(value) {
      if (this.$refs.page.length) {
        this.$refs.page.forEach(page => {
          page.changeLockIndex(value)
        })
      }
    },
    onSwitchIndex(value) {
      if (this.$refs.pages) {
        this.$refs.pages[0].changeLockIndex(value)
      }
    },
  },
}
</script>
