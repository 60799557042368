import HTTPService from '@/api/http/HTTPService'
import PageInterface from './PageInterface'
import DisableInterface from './DisableInterface'

export default {
  getAll: (siteSlug: string, parentUUID: string | null) => HTTPService.get(`site/${siteSlug}/page${parentUUID ? `?parent_uuid=${parentUUID}` : ''}`),
  getOne: (siteSlug: string, pageUUID: string) => HTTPService.get(`site/${siteSlug}/page/${pageUUID}`),
  update: (siteSlug: string, pageUUID: string, payload: PageInterface) => HTTPService.put(`site/${siteSlug}/page/${pageUUID}`, payload),
  toggleDisable: (siteSlug: string, pageUUID: string, payload: DisableInterface) => HTTPService.post(`site/${siteSlug}/page/${pageUUID}/visibility`, payload),
  toggleDisableIndex: (siteSlug: string, pageUUID: string, payload: DisableInterface) => HTTPService.post(`site/${siteSlug}/page/${pageUUID}/index-mode`, payload),
}
