<template>
  <b-card>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          :disabled="generationRunning ? true : false"
          @click="onGenerate"
        >
          <template v-if="generationRunning">
            Идет генерация страниц
            <feather-icon
              icon="LoaderIcon"
              size="18"
              class="ml-1"
            />
          </template>
          <template v-else>
            Сгенерировать страницы
            <feather-icon
              icon="RepeatIcon"
              size="18"
              class="ml-1"
            />
          </template>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <page-table />
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { BCard, BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/order
import PageTable from './page-table/PageTable.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Site from '@/api/http/models/site/site/Site'

export default {
  components: {
    BCard,
    BButton,
    PageTable,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      generationRunning: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  mounted() {
    if (this.currentSite.generation_pages_running) {
      this.generationRunning = true
    } else {
      this.generationRunning = false
    }
  },
  methods: {
    async onGenerate() {
      const responseGenerate = await Site.generate(this.currentSite.slug)

      if (responseGenerate.status === 'success') {
        this.generationRunning = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Генерация страниц запущена',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else if (responseGenerate.errors) {
        this.mixinErrorsParse(responseGenerate.errors)
      }
    },
  },
}
</script>
