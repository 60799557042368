<template>
  <b-modal
    id="page-edit-popup"
    :visible="visible"
    size="lg"
    ok-title="Сохранить изменения"
    cancel-title="Отменить"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <b-card-title
      title-tag="h2"
    >
      {{ page.name }}
    </b-card-title>
    <div class="d-flex mb-4">
      <b-form-checkbox
        v-model="switchChecked"
        :disabled="page.is_locked"
        switch
        @change="onSwitch"
      />
      <div>
        <span v-if="page.is_locked">Страница заблокирована</span>
        <span v-else>
          <span v-if="switchChecked">Страница активна</span>
          <span v-else>Страница НЕ активна</span>
        </span>
      </div>
    </div>
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-tabs
        pills
      >
        <b-tab active>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="TagIcon"
              />
              Мета-теги
            </span>
            <super-tag
              v-if="editPage.inherits_meta_template"
              :level="2"
            >
              Авто
            </super-tag>
            <super-tag
              v-else
              :level="3"
              :disable-icon="true"
            >
              Кастом
            </super-tag>
          </template>
          <div class="py-2">

            <div class="d-flex align-items-center mb-2">
              <div class="mr-1">
                <b-form-checkbox
                  id="inherit_meta-tags"
                  v-model="editPage.inherits_meta_template"
                  name="meta-tags"
                >
                  Использовать тэги из шаблона
                </b-form-checkbox>
              </div>
              <div>
                <div v-if="page.meta_page_template">
                  <super-tag>Шаблон «{{ page.meta_page_template.title }}»</super-tag>
                </div>
                <div v-else>
                  <super-tag :level="3">
                    Кастом
                  </super-tag>
                </div>
              </div>
            </div>

            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="editPage.title"
                v-validate="'required'"
                :disabled="editPage.inherits_meta_template"
                name="title"
              />
              <small
                v-show="errors.has('title')"
                class="text-danger"
              >{{ errors.first('title') }}</small>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="editPage.description"
                v-validate="'required'"
                :disabled="editPage.inherits_meta_template"
                name="description"
              />
              <small
                v-show="errors.has('description')"
                class="text-danger"
              >{{ errors.first('description') }}</small>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="LayoutIcon"
              />
              Контент
            </span>
            <super-tag
              v-if="editPage.inherits_content_template"
              :level="2"
            >
              Авто
            </super-tag>
            <super-tag
              v-else
              :level="3"
              :disable-icon="true"
            >
              Кастом
            </super-tag>
          </template>
          <div class="py-2">
            <div class="d-flex align-items-center mb-2">
              <div class="mr-1">
                <b-form-checkbox
                  id="inherit_content"
                  v-model="editPage.inherits_content_template"
                  name="meta-tags"
                >
                  Использовать контент из шаблона
                </b-form-checkbox>
              </div>
              <div>
                <div>
                  <div v-if="page.content_page_template">
                    <super-tag>Шаблон «{{ page.content_page_template.title }}»</super-tag>
                  </div>
                  <div v-else>
                    <super-tag :level="3">
                      Кастом
                    </super-tag>
                  </div>
                </div>
              </div>
            </div>
            <CKEditor
              v-if="!editPage.inherits_content_template"
              v-model="editPage.content"
              v-validate="'required'"
              name="content"
            />
            <div v-else>
              <div
                v-if="editPage.content"
                class="highlight-input ProseMirror"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="editPage.content" />
              </div>
              <div v-else>
                Контент пустой
              </div>
            </div>
            <small
              v-show="errors.has('content')"
              class="text-danger"
            >{{ errors.first('content') }}</small>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="BoxIcon"
              />
              Прайс-лист
            </span>
            <super-tag
              v-if="editPage.pricelist_enabled"
              :level="2"
              :disable-icon="true"
            >
              <feather-icon
                icon="CheckIcon"
                size="12"
              />
            </super-tag>
            <super-tag
              v-else
              :level="3"
              :disable-icon="true"
            >
              <feather-icon
                icon="XIcon"
                size="12"
              />
            </super-tag>
          </template>
          <div class="py-2">
            <b-form-group>
              <b-form-checkbox
                id="pricelist_inherited"
                v-model="editPage.pricelist_inherited"
                name="meta-tags"
              >
                Прайс-лист будет наследоваться дочерними страницами
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="pricelist_enabled"
                v-model="editPage.pricelist_enabled"
                name="meta-tags"
              >
                Показывать прайс-лист на странице
              </b-form-checkbox>
            </b-form-group>
            <price-list
              v-model="editPage.pricelist"
              :disabled="!editPage.pricelist_enabled"
            />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="SendIcon"
              />
              Форма
            </span>
          </template>
          <div class="py-2">
            <div class="mb-2">
              <b-form-group
                label="Заголовок формы"
                label-for="form_title"
              >
                <b-form-input
                  id="form_title"
                  v-model="editPage.form_title"
                  name="form_title"
                />
                <small
                  v-show="errors.has('form_title')"
                  class="text-danger"
                >{{ errors.first('form_title') }}</small>
              </b-form-group>
            </div>
            <div class="mb-2">
              <b-form-checkbox
                id="show_file_upload-tags"
                v-model="editPage.show_file_upload"
                name="show_file_upload"
              >
                Показывать поле для загрузки файлов
              </b-form-checkbox>
            </div>
            <div class="mb-2">
              <b-form-group
                label="Список email получателей"
                label-for="form_emails"
              >
                <select-tags
                  id="form_emails"
                  v-model="editPage.form_emails"
                  name="form_emails"
                  :emails="true"
                />
                <small
                  v-show="errors.has('form_emails')"
                  class="text-danger"
                >
                  {{ errors.first('form_emails') }}
                </small>
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </form>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectTags from '@core/components/input/SelectTags.vue'
import {
  BCardTitle, BFormGroup, BFormCheckbox, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import Page from '@/api/http/models/page/Page'
import { mapGetters } from 'vuex'
import CKEditor from 'ckeditor4-vue'

import PriceList from './PriceList.vue'
import SuperTag from '../../templates/components/SuperTag.vue'

export default {
  components: {
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    CKEditor: CKEditor.component,
    PriceList,
    SuperTag,
    SelectTags,
  },
  props: {
    pageUUID: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      page: {},
      editPage: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    switchChecked: {
      get() {
        return !this.editPage.is_disabled
      },
      set(newVal) {
        this.editPage.is_disabled = !newVal
      },
    },
  },
  async created() {
    const response = await Page.getOne(this.currentSite.slug, this.pageUUID)

    if (response.data) {
      this.page = response.data
      this.editPage = JSON.parse(JSON.stringify(this.page))
    }
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Page.update(this.currentSite.slug, this.pageUUID, this.editPage)
        if (response.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Страница «${this.page.name}» обновлена`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }

        this.$emit('update', response.data)

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Заполните все поля',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onSwitch(value) {
      this.$emit('switch', value)
    },
  },
}
</script>
