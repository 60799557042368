<template>
  <div>
    <div class="accordion-table__tr">
      <div
        class="accordion-table__td"
        :style="{'width': `calc(35% - ${level * 50}px)`}"
      >
        <feather-icon
          icon="LayoutIcon"
          class="mr-1"
        />
        {{ currentPage.name }}
      </div>
      <div class="accordion-table__td">
        <super-tag
          :level="3"
        >
          {{ currentPage.inherits_meta_template ? 'Авто' : 'Кастом' }}
        </super-tag>
      </div>
      <div class="accordion-table__td">
        <super-tag>
          {{ currentPage.inherits_content_template ? 'Авто' : 'Кастом' }}
        </super-tag>
      </div>
      <div
        class="accordion-table__td"
        @click.stop
      >
        <b-form-checkbox
          v-if="currentPage.type === 'service_brand' || currentPage.type === 'service_brand_model'"
          v-model="switchChecked"
          :disabled="currentPage.is_locked"
          switch
          @change="onSwitch"
        />
      </div>
      <div
        class="accordion-table__td"
        @click.stop
      >
        <b-form-checkbox
          v-model="switchCheckedIndex"
          :disabled="currentPage.is_index_disabled_by_parent"
          switch
          @change="onSwitchIndex"
        />
      </div>
      <div class="accordion-table__td">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-button
              v-b-tooltip.hover.left="'Редактировать'"
              variant="link"
              size="sm"
              @click.stop="showEditPopup = true"
            >
              <feather-icon
                icon="Edit3Icon"
                size="18"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.left="'Просмотр'"
              variant="link"
              size="sm"
              @click.stop="showShowPopup = true"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </div>
          <feather-icon
            v-if="currentPage.children_count > 0"
            class="accordion-table__chevron"
            icon="ChevronDownIcon"
            size="21"
          />
        </div>
      </div>
    </div>
    <page-edit-popup
      v-if="showEditPopup"
      :key="currentPage.uuid"
      :page-u-u-i-d="currentPage.uuid"
      :level="level"
      @hidden="showEditPopup = false"
      @switch="onSwitch"
      @update="updatePage"
    />
    <page-show-popup
      v-if="showShowPopup"
      :key="page.uuid"
      :page-u-u-i-d="page.uuid"
      :level="level"
      @hidden="showShowPopup = false"
    />
  </div>
</template>

<script>
import { BButton, VBTooltip, BFormCheckbox } from 'bootstrap-vue'
import Page from '@/api/http/models/page/Page'
import { mapGetters } from 'vuex'
import PageEditPopup from '../page-edit/PageEditPopup.vue'
import PageShowPopup from '../page-show/PageShowPopup.vue'
import SuperTag from '../../templates/components/SuperTag.vue'

export default {
  components: {
    BButton,
    BFormCheckbox,
    PageEditPopup,
    PageShowPopup,
    SuperTag,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showShowPopup: false,
      currentPage: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    switchChecked: {
      get() {
        return !this.currentPage.is_disabled
      },
      set(newVal) {
        this.currentPage.is_disabled = !newVal
      },
    },
    switchCheckedIndex: {
      get() {
        return !this.currentPage.is_index_disabled
      },
      set(newVal) {
        this.currentPage.is_index_disabled = !newVal
      },
    },
  },
  async created() {
    this.currentPage = await JSON.parse(JSON.stringify(this.page))
  },
  methods: {
    changeLock(value) {
      this.currentPage.is_locked = value
    },
    changeLockIndex(value) {
      this.currentPage.is_index_disabled_by_parent = value
    },
    async onSwitch(value) {
      this.currentPage.is_disabled = !value

      const payload = {
        is_disabled: !value,
      }

      const response = await Page.toggleDisable(this.currentSite.slug, this.currentPage.uuid, payload)

      if (response.status === 'success') {
        this.$emit('switch', !value)
      }
    },
    async onSwitchIndex(value) {
      this.currentPage.is_index_disabled = !value

      const payload = {
        is_index_disabled: !value,
      }

      const response = await Page.toggleDisableIndex(this.currentSite.slug, this.currentPage.uuid, payload)

      if (response.status === 'success') {
        this.$emit('switch-index', !value)
      }
    },
    updatePage(page) {
      this.currentPage = page
    },
  },
}
</script>
