<template>
  <b-modal
    id="show-page-popup"
    :visible="visible"
    size="lg"
    title="Просмотр страницы"
    ok-only
    ok-title="Закрыть просмотр"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <div class="mb-3">
      <p>Обновлено, <span class="text-primary">{{ updatedDate }}</span></p>
    </div>
    <b-card-title
      title-tag="h2"
    >
      {{ currentPage.name }}
    </b-card-title>

    <div>
      <b-form-checkbox
        :checked="!currentPage.is_disabled"
        switch
        disabled
      >
        Страница {{ currentPage.is_disabled ? 'НЕ' : '' }} активна{{ currentPage.is_locked ? '. Страница заблокирована' : '' }}
      </b-form-checkbox>
    </div>

    <div class="d-flex align-items-center mt-4 mb-1">
      <div class="mr-1">
        <feather-icon
          icon="TagIcon"
        />
      </div>
      <div>
        <h3 class="m-0 mr-1">
          Мета-тэги
        </h3>
      </div>
      <div v-if="currentPage.meta_page_template">
        <super-tag>Шаблон «{{ currentPage.meta_page_template.title }}»</super-tag>
      </div>
      <div v-else>
        <super-tag :level="3">
          Кастом
        </super-tag>
      </div>
    </div>
    <b-form-group
      label="Title"
    >
      <div class="highlight-input ProseMirror">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="currentPage.title" />
      </div>
    </b-form-group>

    <b-form-group
      label="Description"
    >
      <div class="highlight-input ProseMirror">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="currentPage.description" />
      </div>
    </b-form-group>

    <div class="d-flex align-items-center mt-4 mb-1">
      <div class="mr-1">
        <feather-icon
          icon="LayoutIcon"
        />
      </div>
      <div>
        <h3 class="m-0 mr-1">
          Контент
        </h3>
      </div>
      <div v-if="currentPage.content_page_template">
        <super-tag>Шаблон «{{ currentPage.content_page_template.title }}»</super-tag>
      </div>
      <div v-else>
        <super-tag :level="3">
          Кастом
        </super-tag>
      </div>
    </div>
    <div
      v-if="currentPage.content"
      class="highlight-input ProseMirror"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="currentPage.content" />
    </div>
    <div
      v-else
    >
      Нет контента
    </div>

    <div class="d-flex align-items-center mt-4 mb-1">
      <div class="mr-1">
        <feather-icon
          icon="LayoutIcon"
        />
      </div>
      <div>
        <h3 class="m-0 mr-1">
          Прайс-лист
        </h3>
      </div>
    </div>
    <div v-if="currentPage.pricelist">
      <h5 class="mb-1">
        {{ currentPage.pricelist.header }}
      </h5>
      <table class="table">
        <tr>
          <th
            v-for="(column, index) in currentPage.pricelist.columns"
            :key="index"
          >
            <span v-if="!column.is_hidden">
              {{ column.title }}
            </span>
          </th>
        </tr>
        <tr
          v-for="(row, index) in currentPage.pricelist.rows"
          :key="index"
        >
          <td
            v-for="(title, index2) in row"
            :key="index2"
          >
            <span v-if="!currentPage.pricelist.columns[index2].is_hidden">
              <s v-if="currentPage.pricelist.columns[index2].is_stroked">
                {{ title }}
              </s>
              <span v-else>
                {{ title }}
              </span>
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      Нет прайс-листа
    </div>

    <div class="d-flex align-items-center mt-4 mb-1">
      <div class="mr-1">
        <feather-icon
          icon="SendIcon"
        />
      </div>
      <div>
        <h3 class="m-0 mr-1">
          Форма
        </h3>
      </div>
    </div>
    <div class="mb-4">
      <div class="mb-2">
        <b-form-group
          label="Заголовок формы"
          label-for="form_title"
        >
          <div class="highlight-input ProseMirror">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="currentPage.form_title || '...'" />
          </div>
        </b-form-group>
      </div>
      <div class="mb-2">
        <b-form-checkbox
          id="show_file_upload-tags"
          v-model="currentPage.show_file_upload"
          name="show_file_upload"
          disabled
        >
          Показывать поле для загрузки файлов
        </b-form-checkbox>
      </div>
      <div class="mb-2">
        <b-form-group
          label="Список email получателей"
          label-for="form_emails"
        >
          <span
            v-for="(email, indexEmail) in currentPage.form_emails"
            :key="indexEmail"
          >{{ email }}{{ currentPage.form_emails.length !== (indexEmail + 1) ? ', ' : '' }}</span>
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import {
  BCardTitle, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Page from '@/api/http/models/page/Page'
import { mapGetters } from 'vuex'
import SuperTag from '../../templates/components/SuperTag.vue'

export default {
  components: {
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    SuperTag,
  },
  props: {
    pageUUID: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      currentPage: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    updatedDate() {
      return moment(this.currentPage.updated_at).fromNow()
    },
  },
  async created() {
    const response = await Page.getOne(this.currentSite.slug, this.pageUUID)

    if (response.data) {
      this.currentPage = response.data
    }

    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide('show-page-popup')
      })
    },
  },
}
</script>
