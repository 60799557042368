<template>
  <div :class="{'disabled-overlay': disabled}">
    <b-form-group
      label="Заголовок таблицы"
    >
      <b-form-input
        v-model="editPricelist.header"
        placeholder="Название таблицы"
        @input="onInput"
      />
    </b-form-group>
    <div>
      <div class="price-table">
        <div class="price-table__head">
          <div class="row">
            <div class="col-11">
              <div class="row">
                <div
                  v-for="(col, index) in editPricelist.columns"
                  :key="index"
                  class="col"
                >
                  <b-form-input
                    v-model="col.title"
                    class="mb-1"
                    :placeholder="placeholders[index]"
                    @input="onInput"
                  />
                  <b-form-group>
                    <b-form-checkbox
                      v-model="col.is_stroked"
                      name="meta-tags"
                      @input="onInput"
                    >
                      Зачеркнуто
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="col.is_hidden"
                      name="meta-tags"
                      @input="onInput"
                    >
                      Скрыто
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-list__body">
          <div
            v-for="(row, index) in editPricelist.rows"
            :key="index"
            class="row mt-1"
          >
            <div class="col-11">
              <div class="row">
                <div
                  v-for="(_, index2) in row"
                  :key="index2"
                  class="col"
                >
                  <b-form-input
                    v-model="row[index2]"
                    @input="onInput"
                  />
                </div>
              </div>
            </div>
            <div class="col-1">
              <b-button
                v-b-tooltip.hover.top="'Удалить строку'"
                size="sm"
                variant="link"
                @click.stop="removeRow(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <b-button
        v-b-tooltip.hover.right="'Добавить строку'"
        size="sm"
        variant="primary"
        class="d-flex align-items-center mt-1"
        @click.stop="addRow()"
      >
        Добавить строку
        <feather-icon
          icon="PlusIcon"
          size="18"
          class="ml-1"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BFormGroup, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholders: [
        'Название',
        'Цена',
        'Цена со скидкой',
      ],
      editPricelist: {
        header: '',
        columns: [
          {
            title: '',
            is_hidden: false,
            is_stroked: false,
          },
          {
            title: '',
            is_hidden: false,
            is_stroked: false,
          },
          {
            title: '',
            is_hidden: false,
            is_stroked: false,
          },
        ],
        rows: [
          [
            '',
            '',
            '',
          ],
        ],
      },
    }
  },
  created() {
    this.editPricelist = { ...this.editPricelist, ...this.value }
  },
  methods: {
    onInput() {
      this.$emit('input', this.editPricelist)
    },
    addRow() {
      this.editPricelist.rows.push([
        '',
        '',
        '',
      ])
    },
    removeRow(index) {
      this.editPricelist.rows.splice(index, 1)
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
  .price-table {
    &__head {
      background: #F3F2F7;
      padding: 10px;
      border-radius: 4px;
    }

    &__body {
      padding: 10px;
    }
  }

  .disabled-overlay {
    opacity: .5;
    pointer-events: none;
  }
</style>
