<template>
  <div>
    <div class="accordion-table">
      <div class="accordion-table__head">
        <div class="accordion-table__tr">
          <div class="accordion-table__td">
            Страница
          </div>
          <div class="accordion-table__td">
            Мета-тэги
          </div>
          <div class="accordion-table__td">
            Контент
          </div>
          <div class="accordion-table__td">
            Активна
          </div>
          <div class="accordion-table__td">
            Индексация
          </div>
          <div class="accordion-table__td">
            Действия
          </div>
        </div>
      </div>
      <page-table-list
        :parent-uuid="null"
        :level="0"
      />
    </div>
  </div>
</template>

<script>
import PageTableList from './PageTableList.vue'

export default {
  components: {
    PageTableList,
  },
}
</script>
